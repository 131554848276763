<template>
    <div class="datasoures">
        
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @excelExportData="excelExportData" @handFromshow="handFromshow"></Tabheader>
        </div>
        <div class="fromshea" v-show="iShow">
            <el-form :inline="true" size="small"  :model="searchParams" class="demo-form-inline">
                <el-form-item :label="this.$t('devtable.bm')">
                     <Select @handleCheckChange="handleCheckChange"></Select>
                </el-form-item>
                <el-form-item :label="$t('devtable.xm')">
                    <el-input v-model="searchParams.name" :placeholder="$t('devtable.xm')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.ghHH')">
                    <el-input v-model="searchParams.number" :placeholder="$t('devtable.ghHH')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.yf')">
                    <el-date-picker
                        v-model="searchParams.month"
                        type="month"
                        format="yyyy-MM"
                        value-format="yyyy-MM"
                        :placeholder="$t('devtable.yf')">
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item :label="$t('devtable.yf')"> -->
                    <!-- <el-input v-model="searchParams.month" :placeholder="$t('devtable.yf')"></el-input> -->
                <!-- </el-form-item>                 -->
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t('devtable.cx')}}</el-button>
            </el-form-item>
        </el-form>
        </div>
        <Tables @handlpages="handlpages" @handleSizeChange="handleSizeChange" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs"></Tables>
        
    </div>
</template>
<script>
import Tables from '@/components/table'
import Select from '@/components/selectree/selectchoice'
import Tabheader from './components/hebut'
import {attendanceServer} from '../../api/uesr'
import methodlist from '../../utils/methods'
import {formatDate,timestampToTime} from '../../utils/date'
import xlxs from 'xlsx'

export default {
    components:{
        Tables,
        Tabheader,
        Select
    },
    data(){
        return{
            iShow:false,
            searchParams: {
                dept_id: '',
                name: '',
                number: '',
                month: ''
            },
            input:'',
            centerDialogVisible: false,
           
            tableData: [
            ],
            flags: {
                show: true, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
           UserInfo:''
        }
    },
    computed:{
         tableLabel: function(){ 
            return[
                {
                    prop: 'month',
                    label: this.$t('devtable.yf'),
                    align: 'center',
                    width:130
                },
                {
                    prop: 'user_name',
                    label: this.$t('devtable.xm'),
                    align: 'center'
					
                },
                {
                    prop: 'user_number',
                    label: this.$t('devtable.ghHH'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'dept_name',
                    label: this.$t('devtable.bm'),
                    align: 'center',
					width:100
                },
                {
                    prop: 'work_days',
                    label: this.$t('devtable.yqts'),
                    align: 'center',
					width:140
                },
                {
                    prop: 'work_days_real',
                    label: this.$t('devtable.sqts'),
                    align: 'center',
					width:142
                },{
                    prop: 'late_times',
                    label: this.$t('devtable.cdcs'),
                    align: 'center',
					width:100
                },{
                    prop: 'leave_times',
                    label: this.$t('devtable.ztcs'),
                    align: 'center',
					width:140
                },
                {
                    prop: 'card_times',
                    label: this.$t('devtable.yskcs'),
                    align: 'center',
					width:130
                },
                {
                    prop: 'lost_card_times',
                    label: this.$t('devtable.wskcs'),
                    align: 'center',
					width:130
                },{
                    prop: 'ask_leave',
                    label: this.$t('devtable.qjt'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'rlnumber6',
                    label: this.$t('devtable.cct'),
                    align: 'center',
					width:120
                },{
                    prop: 'overtime',
                    label: this.$t('devtable.jbxs'),
                    align: 'center',
					width:100
                },{
                    prop: 'completion',
                    label: this.$t('devtable.kgt'),
                    align: 'center',
					width:110
                }
            ]
         }
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getMonthCheckinRecordList(1);
    },
    methods:{
        handleCheckChange(val){
            this.searchParams.dept_id = val;
        },         
        handRegister(){
            this.centerDialogVisible=true
        },
        handFromshow(){
            this.iShow = !this.iShow 
        },
        onSubmit() {
            console.log('submit!',this.searchParams.month);
            this.getMonthCheckinRecordList(1,this.searchParams.dept_id,this.searchParams.name,this.searchParams.number,this.searchParams.month);
        },
        handlpages(v){
            this.configs.page_index=v
            this.getMonthCheckinRecordList();
        },
        handleSizeChange(v){
            this.configs.page_index=1
            this.configs.pagesize=v
            this.getMonthCheckinRecordList()
        },
        getMonthCheckinRecordList(pageIndex,dept_id,name,number,month){
            const params = {
                method: 'att.report.month.get',
                agent_id: this.UserInfo.agent_id,
                manager_id: this.UserInfo.manager_id,
                page_index: pageIndex?pageIndex:this.configs.page_index, 
                page_size: this.configs.pagesize,
                name: name?name:this.searchParams.name,
                number:number?number: this.searchParams.number,
                dept_id:dept_id?dept_id: this.searchParams.dept_id,
                month:month?month: this.searchParams.month
            }

            attendanceServer(params).then(res => {
                console.log(res);
                this.tableData = res.data.data.records;
                this.tableData.forEach(el=>{
                    if(el.unlock_state == 0){
                        el.unlock_state_hint={
                        name: '进',
                        type: 'primary',
                        state: true
                        }
                    }else{
                        el.unlock_state_hint={
                        name: '出',
                        type: 'primary',
                        state: true
                        }
                    }
                    //el.remark = '备注'
                })
                if(params.page_index==1){
                    this.configs.total = res.data.data.recordCount;
                }
               
            })
        },
        //分页获取全部数据
        excelExportData(){
            var exportData = [];
            var pageData = [];
            var allDataTimer=null;
            var postFlag = false;
            var record_index = 1;
            var record_size = 10;
            var data_count = 0;
            allDataTimer = setInterval(()=>{
                if(postFlag == false){
                    postFlag=true;
                    const params = {
                        method: 'att.report.month.get',
                        agent_id: this.UserInfo.agent_id,
                        manager_id: this.UserInfo.manager_id,
                        page_index: record_index, 
                        page_size: record_size,
                        name: this.searchParams.name,
                        number: this.searchParams.number,
                        dept_id: this.searchParams.dept_id,
                        month: this.searchParams.month
                    }

                    attendanceServer(params).then(res => {
                        console.log(res);
                        postFlag = false;
                       
                        pageData = res.data.data.records;
                        exportData= exportData.concat(pageData);
                        pageData.forEach(el=>{
                            if(el.unlock_state == 0){
                                el.unlock_state_hint={
                                name: '进',
                                type: 'primary',
                                state: true
                                }
                            }else{
                                el.unlock_state_hint={
                                name: '出',
                                type: 'primary',
                                state: true
                                }
                            }
                        })
                         if(record_index == 1)
                            data_count = res.data.data.recordCount;
                        if(record_index * record_size >= data_count){
						    clearInterval(allDataTimer);
                            allDataTimer = null;
                            console.log(exportData);
                            this.excelExportDataSnd(exportData);
						}else{
						    record_index+=1;
                        }
                        
                    })                    
                }
            },500)
        },        
        excelExportDataSnd(dataList){
            let arr = dataList.map(item => {
                if(window.localStorage.getItem('language')=='en'){
                     return {
                         'Month':item.month,
                        'Name':item.user_name,
                        'Job number': item.user_number,
                        'Department':item.dept_name,
                        'Attendance days':item.work_days,
                        'Actual working days':item.work_days_real,
                        'Times of being late':item.late_times,
                        'Number of early leave': item.leave_times,
                        'Swipe times': item.card_times,
                        'Number of times of not swiping card': item.lost_card_times,
                        'Leave days': item.ask_leave,
                        'Travel days': item.rlnumber6,
                        'Overtime hours': item.overtime,
                        'completion': item.completion
                    }

                }else{
                    return {
                        '月份':item.month,
                        '姓名':item.user_name,
                        '工号': item.user_number,
                        '部门':item.dept_name,
                        '应勤天数':item.work_days,
                        '实勤天数':item.work_days_real,
                        '迟到次数':item.late_times,
                        '早退次数': item.leave_times,
                        '刷卡次数': item.card_times,
                        '未刷卡次数': item.lost_card_times,
                        '请假天数': item.ask_leave,
                        '出差天数': item.rlnumber6,
                        '加班时数': item.overtime,
                        '旷工天数': item.completion
                    }
                }
                
            })
            // 将数据转换为表格
            let sheet = xlxs.utils.json_to_sheet(arr)
            let book = xlxs.utils.book_new()
            xlxs.utils.book_append_sheet(book, sheet, "sheet1")
            xlxs.writeFile(book, this.$t('navLeft.kqyb')+'-'+`${timestampToTime()}.xls`)            
        }
    }
}
</script>
<style lang="less" scoped>
.datasoures{
    padding: 20px;
    background-color: #fff;
    
    .fromshea{
        margin-top:20px;
        padding: 20px;
    }
}
.tabletemplate{
    padding: 0px;
    padding-top:20px ;
    
}
</style>